@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700";
header a, header a:visited, header a:hover, header a:active {
  color: #000;
}

@media only screen and (max-width: 600px) {
  header a span.big_hi {
    font-size: 4.3rem;
  }
}

footer {
  text-align: center;
  height: 50px;
  display: block;
}

footer .footer-links {
  margin-top: 30px;
}

footer .gray-it {
  color: #cbcbcb;
}

.profile-pic {
  width: 200px;
  transition: transform 1s;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.profile-pic:hover {
  transform: scale(1.4);
}

.blog-post {
  min-height: 300px;
}

.blog-post-list {
  font-size: 1.1rem;
  line-height: 2.6rem;
}

.blog-post-list ul {
  list-style: none;
}

.blog-single-post {
  margin-top: 60px;
  line-height: 1.6rem;
}

.blog-single-post .blog-title {
  max-width: 85%;
  margin: 0 auto;
}

.blog-single-post .blog-content {
  padding: 10px 50px;
}

.blog-single-post .blog-content h2 {
  padding: 30px 0 10px;
}

.blog-single-post .blog-content h3 {
  padding: 10px 0 0;
  font-weight: 600;
}

.blog-single-post .blog-title {
  text-align: center;
}

.blog-single-post p {
  margin: 20px 0;
}

.blog-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.blog-content .embed-video {
  text-align: center;
}

body {
  background-color: #f1f1f1;
  margin: 0;
  padding: 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

p {
  margin: 0;
  padding: 0;
}

hr {
  width: 90%;
  border: 0;
  border-top: .5px solid #cbcbcb;
  margin-bottom: 0;
}

h1 {
  font-size: 1.8rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}

a, a:visited, a:hover, a:active {
  color: #0c6fe3;
  text-decoration: none;
}

blockquote {
  color: #a0a0a0;
  border-left: 3px solid #e5e5e5;
  margin-left: 15px;
  padding: 3px 0 3px 15px;
  font-size: 1.1rem;
  line-height: 1.8rem;
}

#container {
  grid-template-rows: 20px 1fr 20px;
  grid-template-columns: 1fr 900px 1fr;
  display: grid;
}

#wrapper {
  background-color: #fff;
  grid-area: 2 / 2;
  display: block;
  box-shadow: 0 2px 4px 1px #0000000f;
}

header {
  text-align: center;
  letter-spacing: 1.5px;
  padding: 30px 100px 0;
  font-size: .9rem;
  font-weight: bold;
  display: block;
}

header span.big_hi {
  font-size: 6rem;
}

main {
  line-height: 1.6rem;
}

main section {
  padding: 30px 50px 30px 70px;
}

main section .about-me {
  grid-template-rows: 1fr;
  grid-template-columns: 28% 72%;
  display: grid;
}

main section .about-me .about-me-content {
  padding: 10px 30px 30px;
}

main section .about-me .about-me-content .about-me-content-line {
  margin-top: 20px;
}

@media only screen and (max-width: 965px) {
  #container {
    grid-template-columns: 100%;
    display: grid;
  }

  #wrapper {
    grid-column: 1;
  }

  main section {
    padding: 25px;
  }

  main section .about-me {
    grid-template-columns: 100%;
    display: grid;
  }

  main section .about-me .about-me-content {
    padding: 0;
  }

  .profile-pic {
    margin: 0 auto;
  }

  .blog-single-post .blog-content {
    padding: 10px 25px;
  }
}

@media only screen and (max-width: 600px) {
  section .blog-post-list {
    font-size: 1rem;
  }

  section .blog-post-list ul {
    padding: 0;
  }
}

/*# sourceMappingURL=index.105c1655.css.map */
