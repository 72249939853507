/* CSS Reset */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');



body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  padding: 0;
}

hr {
  width: 90%;
  border: 0;
  border-top: 0.5px solid #cbcbcb;
  margin-bottom: 0;
}

h1 {
  font-size: 1.8rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}

a,
a:visited,
a:hover,
a:active {
  color: #0c6fe3;
  text-decoration: none;
}

blockquote {
  font-size: 1.1rem;
  border-left: 3px solid #e5e5e5;
  padding: 3px 0 3px 15px;
  margin-left: 15px;
  line-height: 1.8rem;
  color: #a0a0a0;
}
