header a,
header a:visited,
header a:hover,
header a:active {
  color: #000000;
}

@media only screen and (max-width: 600px) {
  header a span.big_hi {
    font-size: 4.3rem;
  }
}
