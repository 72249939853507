.profile-pic {
  width: 200px;
  transition: transform 1s;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.profile-pic:hover {
  transform: scale(1.4);
}
