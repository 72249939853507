.blog-post {
  min-height: 300px;
}
.blog-post-list {
  font-size: 1.1rem;
  line-height: 2.6rem;
}

.blog-post-list ul {
  list-style: none;
}

.blog-single-post {
  margin-top: 60px;
  line-height: 1.6rem;
}

.blog-single-post .blog-title {
  max-width: 85%;
  margin: 0 auto;
}
.blog-single-post .blog-content {
  padding: 10px 50px;
}

.blog-single-post .blog-content h2 {
  padding: 30px 0 10px 0;
}

.blog-single-post .blog-content h3 {
  padding: 10px 0 0 0;
  font-weight: 600;
}

.blog-single-post .blog-title {
  text-align: center;
}

.blog-single-post p {
  margin: 20px 0;
}

.blog-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.blog-content .embed-video {
  text-align: center;
}
