footer {
  display: block;
  text-align: center;
  height: 50px;
}

footer .footer-links {
  margin-top: 30px;
}

footer .gray-it {
  color: #cbcbcb;
}
