#container {
  display: grid;
  grid-template-columns: 1fr 900px 1fr;
  grid-template-rows: 20px 1fr 20px;
}

#wrapper {
  grid-column: 2;
  grid-row: 2;
  display: block;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.06);
}

header {
  display: block;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;
  font-size: 0.9rem;
  padding: 30px 100px 0px 100px;
}

header span.big_hi {
  font-size: 6rem;
}

main {
  line-height: 1.6rem;
}

main section {
  padding: 30px 50px 30px 70px;
}
main section .about-me {
  display: grid;
  grid-template-columns: 28% 72%;
  grid-template-rows: 1fr;
}

main section .about-me .about-me-content {
  padding: 10px 30px 30px 30px;
}

main section .about-me .about-me-content .about-me-content-line {
  margin-top: 20px;
}

/**
break points
**/
@media only screen and (max-width: 965px) {
  #container {
    display: grid;
    grid-template-columns: 100%;
  }

  #wrapper {
    grid-column: 1;
  }

  main section {
    padding: 25px;
  }

  main section .about-me {
    display: grid;
    grid-template-columns: 100%;
  }

  main section .about-me .about-me-content {
    padding: 0;
  }

  .profile-pic {
    margin: 0 auto;
  }

  .blog-single-post .blog-content {
    padding: 10px 25px;
  }
}

@media only screen and (max-width: 600px) {
  section .blog-post-list {
    font-size: 1rem;
  }

  section .blog-post-list ul {
    padding: 0;
  }
}
